.pill {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-radius: 1.8rem; */
  border-radius: 1rem;
  /* background-color: greenyellow; */
  /* border-radius: 0.8rem; */
  width: fit-content;
  max-height: 2rem;
  /* color: var(--dark-grey); */
  gap: 0.4rem;

  padding-left: 0.4rem;
  padding-right: 0.4rem;
  transition: background-color 1s ease-in;
}

.pill-selected {
  color: white;
  outline: 2px solid white;
  outline-offset: -0.1rem;
}

.pill-hover:hover {
  /* color: white; */
  /* transition: color 50ms ease-out; */
  /* filter: brightness(2); */
  /* filter: invert(0.3); */
  opacity: 80%;
}

.pill h2 {
  font-weight: 700;
  font-size: 0.8rem;
  display: hidden;
  /* mix-blend-mode: difference; */
  /* color: white; */
  /* filter: brightness(0.2); */
}

.pill-small {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.pill-small h2 {
  font-weight: 700;
  font-size: 0.6rem;
}

.pill-small .icon-button {
  font-size: small;
}

.pill-big {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.pill-big h2 {
  /* font-weight: ; */
  font-size: 1rem;
}

.pill .pill-icon {
  padding: 1.25rem;
}

@media (max-width: 866px) {
  .pill > h2 {
    display: none;
  }
}
