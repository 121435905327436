.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
  max-width: 96vw;
  min-width: 100%;
  /* margin: 0rem 0rem; */
  gap: 0.5rem;
  /* column-gap: 10px; */
  margin-top: 4rem;
}

/* .masonry-grid_column { */
/* background-clip: padding-box; */
/* } */

/* Style your items */
.masonry-grid_column > div {
  margin-bottom: 0.5rem;
}
