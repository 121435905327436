#profile-button {
  height: 2rem;
  width: auto;
  /* border: 1px solid black; */
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  color: white;
}

#top-bar {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 2rem;
  padding: 0.8rem 1vw 0.4rem 1.4rem;
  column-gap: 0.5rem;
  z-index: 999991;
}

#top-bar-left {
  font-size: 0.5rem;
  /* z-index: 999990; */
}

#top-bar-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profile-menu-outter {
  border-radius: 0.625rem;
  position: absolute;
  top: 3rem;
  right: 1vw;
  background-color: var(--dark-grey);
  /* z-index: 10; */
}

.profile-menu {
  border-radius: 0.625rem;
  border: white solid;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.4rem;
  /* background-color: rgb(255, 255, 255, 0.4); */
  margin: 0rem;
  color: white;
}

.profile-tile {
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: space-evenly;
  overflow: hidden;
  align-items: center;
  border-radius: 0.625rem;
  border: white solid;
  font-size: small;
  padding: 0.1rem 0.4rem;
  cursor: pointer;
}

.profile-tile:hover {
  /* background-color: #d6d6d6; */
  background-color: rgb(255, 255, 255, 0.8);
}

#user-name {
  font-weight: 400;
}

#top-bar-center {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 2rem; */

  height: fit-content;
  padding-top: 0.4rem;
  width: 15rem;
  margin-left: 6.6vw;
}

.resource-search-input {
  font-size: smaller;
  font-weight: normal;
  height: 1.4rem;
  /* height: 1.4rem; */
  /* width: 20vw; */
  /* min-width: 40rem; */
  /* max-width: 50vw; */
  /* font-size: min(max(0.5rem, 1.5vw), 0.8rem); */
  /* width: min(max(50rem, 60vw), 50vw); */
  width: 100%;
  /* border: 1.4px solid var(--dark-grey); */
  /* border: 0.1rem solid #ebebeb; */
  /* border: 0.06rem solid var(--dark-grey); */
  border: 1px solid #dfe1e5;
  border-radius: 1rem;
  /* border-radius: 32px; */
  /* border-radius: 0.625rem; */

  padding-left: 0.8rem;
  padding-right: 0.8rem;
  /* margin-top: 0.4rem; */
  cursor: pointer;
  text-align: center;
  color: var(--dark-grey);
}

.resource-search-input:focus {
  cursor: text;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223, 225, 229, 0);
  outline: none;
}

.resource-search-input:hover {
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223, 225, 229, 0);
}

.resource-search-input::placeholder {
  text-align: center;
}

.resource-search-input:focus::placeholder {
  opacity: 0;
}
