.card {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* border: 0.1rem solid #ebebeb; */
  border: 1px solid #dfe1e5;
  border-radius: 0.625rem;
  padding: 0.6rem 1rem 0rem 1rem;
  /* max-width: 20rem; */
  min-width: 14rem;
  min-height: fit-content;
  /* overflow: hidden; */

  transition: transform 0.5s;
  transform-style: preserve-3d;
  position: relative;
  /* transform-origin: center right; */
  /* transition: all 5s ease; */
  /* box-shadow: 0 0.5px 0.5px hsl(0deg 0% 0% / 0.075),
    0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075),
    0 4px 4px hsl(0deg 0% 0% / 0.075), 0 8px 8px hsl(0deg 0% 0% / 0.075); */
  overflow-wrap: anywhere;
}

.card:hover {
  /* .card:focus { */
  /* transform: translateY(-2.6px); */
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223, 225, 229, 0);
  /* outline: none; */
  /* z-index: 999990 !important; */
  z-index: 999990;
}

.card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  max-width: 100%;
}
.card-top .title {
  display: flex;
  align-items: center;
  max-width: 100%;
  gap: 1rem;
  font-size: small;
  font-weight: bold;
  overflow: hidden;
  text-decoration: none;
  color: var(--dark-grey);
  cursor: pointer;
}
.card-top .title .icon {
  max-width: 1.4rem;
}

.card-top i {
  font-size: x-small;
  font-style: normal;
  font-weight: bolder;
}

.card-top .right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.2rem;
  font-size: small;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: fit-content;
  min-height: 2rem;
}

.card-content .iframe {
  aspect-ratio: 16/9;
  border-radius: 0.625rem;
  max-width: 100%;
  max-height: 100%;
}

.card-content img {
  border-radius: 0.625rem;
  max-width: 100%;
  max-height: 12rem;
  /* cursor: pointer; */
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: small;
  font-weight: bold;
  max-width: 100%;
  overflow: hidden;
  margin: 0.8rem 0rem 0.8rem 0rem;
  min-height: 0.9rem;
}

.card-footer .left-text {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.4rem;
}

.card-footer .right-text {
  max-width: 90%;
  overflow: hidden;
}

.card-footer select {
  /* font-size: large; */
  /* border: none; */
  /* outline: none; */
  background: none;
  cursor: pointer;
  /* width: fit-content; */
  min-width: 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* padding: 2px 30px 2px 2px; */
  border: none;
  font-weight: bold;
  padding-top: 0.1rem;
}

.card-footer i {
  font-size: smaller;
  font-style: normal;
  font-weight: normal;
}

.card.is-flipped {
  /* overflow: visible; */
  transform: rotateY(180deg);
  /* backface-visibility: hidden; */
}
/* .card.is-flipped {
  transform: translateX(-100%) rotateY(-180deg);
} */

.card__face {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* -moz-backface-visibility: hidden; */
  /* max-height: 100%; */
  /* max-width: 100%; */
  /* min-height: 12rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.card__face--front {
  /* height: 95%; */
}

.card__face--back {
  height: 95%;
  max-width: 92%;
  position: absolute;
  /* padding: 0.6rem rem 0rem 1rem; */
  /* top: 0; */
  /* right: 0rem; */
  /* height: 80%; */
  transform: rotateY(180deg);

  /* display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left; */

  /* flex-wrap: wrap; */
  overflow: auto;
}

.img-with-icons {
  position: relative;
  display: flex;
  justify-content: center;
}

.img-with-icons img {
  /* box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075); */
  /* border: 3px solid red; */
}

.img-with-icons:hover .icons {
  visibility: visible;
}

.img-with-icons .icons {
  position: absolute;
  display: flex;
  gap: 0.5rem;
  right: 1rem;
  top: 1rem;
  visibility: hidden;
}

.img-with-icons .icons .icon-button {
  font-size: larger;
}

.meta-item {
  padding: 0.2rem 0.4rem 0.2rem 0rem;
}

.card-deletion-mode {
  border-color: rgba(248, 63, 63, 0.75);
  cursor: pointer;
  animation: tilt-n-move-shaking 0.25s linear infinite;
}

.pin-icon {
  position: fixed;
  right: 0.9rem;
  bottom: 0.5rem;
  visibility: hidden;
}

.card.is-flipped .pin-icon {
  visibility: visible;
}

@keyframes tilt-n-move-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(0px, 0px) rotate(0.2deg);
  }
  50% {
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    transform: translate(-1px, 1px) rotate(-0.2deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.tag-pills-list {
  display: flex;
  margin-top: 0.4rem;
  gap: 0.2rem;
  flex-wrap: wrap;
}

.tag-create-input-with-suggestions {
  /* position: absolute; */
  /* bottom: -1.4rem; */
  /* font-size: small; */
  /* margin-top: 2.2rem; */
  /* height: 10rem; */
  /* width: 10rem; */
  /* margin-left: 0.2rem; */
  /* border-radius: 0.7rem; */
  /* border: 1px solid #2a2a2a3d; */
  width: fit-content;

  /* z-index: 9999999 !important; */
}

.tag-create-input {
  position: relative;
  /* bottom: 2rem; */
  font-size: small;
  height: 1rem;
  margin-left: 0.2rem;
  top: 0.08rem;
  /* margin-left: 0.2rem; */
  /* min-height: 1rem; */
  /* max-height: 1.2rem; */
  /* width: 20vw; */
  /* min-width: 40rem; */
  /* max-width: 50vw; */
  /* font-size: min(max(0.5rem, 1.5vw), 0.8rem); */
  /* width: min(max(50rem, 60vw), 50vw); */
  /* border: 1px solid var(--dark-grey); */
  border-radius: 0.7rem;
  border: 1px solid #2a2a2a3d;
  width: 1rem;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease-out;
  padding: 0.2rem 0.2rem;
  background-color: transparent;
  /* text-transform: lowercase; */
}

.tag-create-input:hover:not(:focus) {
  /* transform: translateY(-2.6px);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  width: 1.8rem;
  border: 1px solid white;
}

.tag-create-input:focus {
  width: 5.6rem;
  cursor: text;
  border-radius: 0.625rem;
  padding: 0.2rem 0.4rem;
}

.tag-create-input:focus::placeholder {
  color: transparent;
}

.tag-create-input-suggestions {
  /* display: none; */
  padding: 0rem 0.4rem 0.5rem;
  position: relative;
  top: 0.4rem;
  /* margin-top: 2.2rem; */
  /* height: 10rem; */
  /* width: 10rem; */
  height: fit-content;
  max-height: 60rem;
  min-width: 8rem;
  width: fit-content;
  max-width: 10rem;
  /* margin-left: 0.2rem; */
  border-radius: 0.7rem;
  border: 1px solid #2a2a2a3d;
  background-color: white;
  z-index: 999999;
  transition: opacity 2s ease;
}

/* .tag-create-input:focus ~ .tag-create-input-suggestions {
  display: block;
} */

.resource-description-wrapper {
  margin-bottom: 0rem;
  margin-top: 0.4rem;
  /* max-width: 18rem; */
}

.resource-description {
  background-color: transparent;
  cursor: pointer;
  border-radius: 0.625rem;
  border: 0;
  font-size: small;
  font-weight: normal;
  overflow-wrap: anywhere;
  max-height: 5rem;
  min-height: fit-content;
  /* width: 100%; */
  max-width: 100%;
  padding: 0.4rem 0.6rem;
  overflow: auto;
  white-space: pre-wrap;
  cursor: text;
}

.resource-description:hover {
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223, 225, 229, 0);
}

.resource-description:focus {
  cursor: text;
}
