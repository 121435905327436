.image-background {
  /* background-image: url('/public/background/blue_light_shadow.jpeg'); */
  background-image: url('/public/background/mountains_clouds.jpeg');
  /* background-image: url('/public/background/night_sky_1.jpeg'); */
  /* background-image: url('/public/background/orange_blues.jpeg'); */
  /* background-image: url('/public/background/red_orange_wall.jpeg'); */
  background-size: 100%;
  /* width: 300px; */
  /* height: 300px; */
  /* border: 2px solid; */
  /* color: pink; */
}

* {
  box-sizing: inherit;
}

:root {
  /* --dark-grey: #202020; */
  --dark-grey: #2a2a2a;
  --disabled-color: rgba(160, 157, 157, 0.639);
}

.App {
  text-align: center;
  color: var(--dark-grey) !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.translucid-white-background {
  background-color: rgb(255, 255, 255, 0.4);
}

.logo {
  cursor: pointer;
}
.logo-outer {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
  border: 0.4rem solid var(--dark-grey);
}
.logo-inner {
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  border: 0.2rem solid var(--dark-grey);
}

.icon-button {
  cursor: pointer;
  font-size: larger;
  color: var(--dark-grey);
}
.icon-button:hover {
  /* color: rgba(255, 255, 255, 0.482); */
  color: rgba(88, 88, 88, 0.684);
}

.main-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.content {
  width: 82vw;
  overflow: hidden;
  position: relative;
  /* top: 1.5rem; */
  top: 0rem;
  left: 12vw;
}

.hidden {
  display: none;
}

.mirror-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.disabled-button {
  cursor: default;
  color: var(--disabled-color);
}

.disabled-button:hover {
  color: var(--disabled-color);
}

.pointer {
  cursor: pointer;
}
