.view {
  /* margin-top: 1rem; */
  /* padding: 1rem; */

  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 0.5rem;
  /* height: 100vh; */
  min-height: 50vh;
}

.iframe-wrapper {
  /* background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 16/9;

  top: 2rem;
  position: fixed;
  border-radius: 0.625rem;
  overflow: hidden; */

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 11;
}

.iframe-wrapper .url {
  position: fixed;
  bottom: 0px;
  right: 50%;
  left: auto;
  transform: translateX(50%);
  font-weight: bold;
}

.loader {
  box-sizing: border-box;
  position: relative;
  width: 48px;
  height: 48px;
  animation: spin 1s linear infinite;
}
.loader:after,
.loader:before {
  content: '';
  width: 1rem;
  height: 1rem;
  position: absolute;
  border-radius: 50%;
  background: var(--dark-grey);
  animation: spin 1s linear infinite;
  transform-origin: 0px 100%;
  overflow: visible;
}
.loader:before {
  transform-origin: 0 50%;
  background: #fff;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
