html {
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  /* background: linear-gradient(-45deg, #db6440, #cd3ce7, #1ea1d1, #1a9c7d); */
  /* background: linear-gradient(-45deg, #D8C8E8, #EBD3EA, #FAF7ED, #DAEBD6, #BFE3D6, #A7C4CF); */
  background: linear-gradient(
      -45deg,
      #f1b988,
      #ffe97b,
      #bae96d,
      #8ce2cb,
      #7ac8fc,
      #b0a7f7
    )
    fixed;
  background-size: 400% 400%;
  /* animation: gradient 15s ease infinite; */
  animation: gradient 25s ease infinite;
  min-height: 100vh;
  height: 100%;
  /* height: 100vh; */

  max-width: 100%;
  overflow-x: hidden;
  overscroll-behavior: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
