.floating-buttons-section {
  position: fixed;
  bottom: 2.4vh;
  right: 1vw;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.4rem;
}

/* left: 1vw; */
/* bottom: 2.4vh; */

.floating-buttons-section .icon-button {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: large;
}

.floating-buttons-section .refresh-resources-section .refresh-icon:active {
  background-color: var(--dark-grey);
  color: white;
}

.add-resource-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* height: 4rem; */
}

.add-resource-options-wrapper-wrapper {
  display: none;
  position: fixed;
  bottom: 2px;
  left: 28vw;
  width: 50vw;
  min-height: 5rem;
}
.add-resource-options-wrapper {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.add-resource-options {
  display: flex;
  /* transition: opacity 1s ease-out; */
  /* opacity: 0; */
  position: absolute;
  bottom: -3rem;
  transition: all 0.5s ease-in-out;
  width: 97%;

  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  height: 2rem;
}

.visible {
  display: block;
  /* opacity: 1; */
  /* height: auto; */
}

.slide {
  transition: all 0.2s ease-in-out;
  bottom: 1.5rem;
}

.input-with-icons {
  height: 100%;
  width: 90%;
  position: relative;
}

.add-resource-options .input-with-icons input {
  font-size: medium;
  height: 100%;
  /* width: 20vw; */
  /* min-width: 40rem; */
  /* max-width: 50vw; */
  /* font-size: min(max(0.5rem, 1.5vw), 0.8rem); */
  /* width: min(max(50rem, 60vw), 50vw); */
  width: 100%;
  border: 1.5px solid var(--dark-grey);
  border-radius: 0.625rem;
  padding-left: 0.8rem;
}

.add-resource-options .input-with-icons .input-icons {
  position: absolute;
  margin: auto;
  top: 0.4rem;
  bottom: 0;
  right: 0rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}
.add-resource-options .input-with-icons .icon-button {
  height: 1rem;
  width: 1rem;
  color: var(--dark-grey);
}

.add-resource-options select {
  font-size: small;
  font-weight: bold;
  height: 100%;
  border: 1px solid white;
  border-radius: 0.625rem;
  width: fit-content;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 0 0 0 0.4rem;
  color: var(--dark-grey);
  background-color: white;
  border: 1.5px solid var(--dark-grey);
}

.black-and-white {
  color: white;
  background-color: var(--dark-grey);
}

.rotate {
  -moz-transition: transform 0.5s ease;
  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}

.rotate.full {
  transition: transform 0.5s ease;
  -moz-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.rotate.quarter {
  transition: transform 0.3s ease;
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icon-button-floating {
  color: white;
  background-color: var(--dark-grey);
}
.icon-button-floating:hover {
  color: rgba(255, 255, 255, 0.795);
}

.invisible-button {
  background-color: transparent;
  border: 0;
}

.white-border {
  border: 1px solid white;
}
