.login-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  height: auto;
  width: 20%;
  /* background-color: #ebebeb; */
  background-color: rgb(255, 255, 255, 0.4);
  border: rgb(214, 214, 214) solid;
  border-radius: 0.625rem;
  padding: 2rem;
  /* opacity: 0.5; */
}

.login-button {
  background-color: #4286f4;
  border: 1px solid transparent;

  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 0.8rem;
  font-weight: 700;
  height: 2.5rem;
  overflow: hidden;
  padding: 0.5rem;
  width: 100%;
  /* z-index: 1; */
  transition: all ease 0.5s;
}

.login-button:hover {
  /* background-color: rgb(84, 84, 84, 0.8); */
  /* background-color: rgb(255, 255, 255, 0.2); */
  /* color: rgba(116, 116, 116, 1); */
  /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); */
  /* box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3); */
  transform: translateY(-2px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.login-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
}
