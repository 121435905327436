.sidebar-container {
  /* width: fit-content; */
  /* width: 3rem; */
  /* min-width: 12rem; */
  width: 11vw;
  min-width: 2vw;
  padding: 0rem 0.5rem 1rem 0.5rem;
  /* margin: 1.5rem auto 0 auto; */
  margin: 0 auto 0 auto;
  /* box-shadow: 0 0 6px hsl(210 14% 90%); */
  border-radius: 0.625rem;
  color: var(--dark-grey);
  position: fixed;
  top: 11vh;
  left: 0.2rem;
  z-index: 3;
  height: 81.1vh;
  overflow: auto;
  transition: left 0.8s ease-in-out;
}

.hidden-sidebar {
  left: -18vw;
}

.sidebar-icon {
  position: fixed;
  top: 9vh;
  left: 1.8vw;
  z-index: 400;
}

.pills-container {
  display: flex;
  gap: 0.4rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: fit-content;
  max-height: 94%;
}

.clear-selection-button {
  background: none;
  border: none;
  font-weight: 700;
  font-size: 0.8rem;
}

.clear-selection-button:hover {
  color: white;
}
